@import url("https://use.typekit.net/dni3rdu.css");
@import './variables.scss';

@mixin flexCenterColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

* {
    box-sizing: border-box;
    font-size: 10px;
}

body {
    background-color: $background;
    // cursor : none;
    margin: 0 auto;
    color: white;
    font-size: 10px;
    font-family: 'fira-sans', serif;
    font-weight: 200;
    font-style: normal;
}

@media (max-width: 900px) {
    * {
        font-size: 9px;
    }
}

@media (max-width: 700px) {
    *{
        font-size: 8px;
    }
}

@media (max-width: 600px) {
    * {
        font-size: 6px;
    }
}

@media (max-width: 350px) {
    * {
        font-size: 5px;
    }
}

::selection {
    color: white;
    background: $green;
}

#mouseSVG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
    background: transparent;
}


.mainPageHeader {
    @include flexCenterColumn;
    height: 100vh;
    width: 100vw;
    background-image: url('../img/header.jpg');
    background-position: center;
    background-size: cover;

    .logoBox {
        @include flexCenterColumn;
        height: 20rem; width: 55rem;
        background-color: rgba(42, 30, 23, .7);
        border-radius: 1rem;

        .companyName {
            font-family: "Menlo", sans-serif;
            font-size: 5.5em;
            color: $green;
            margin:0.2em 0;
        }
        .companyOwner {
            font-size: 2.8em;
            color: #eee;
        }
    }
}   


.currentMessageBox {
    @include flexCenterColumn;
    position: absolute;
    bottom: 5em;
    width: 100%;
    margin: 0 auto;
    
    .backgroundBox {
        @include flexCenterColumn;
        width: 85%;
        max-width: 800px;
        background-color: rgba(42, 30, 23, .7);
        padding: 3em;
        border-radius: 1em;
    }
    
    .growingMessage {
        font-size: 2.2em;
        text-align: center;
        padding: 1em
    }
    .ctaMessage {
        font-size: 2em;
        text-align: center;
    }
}
